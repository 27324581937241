import axios from "axios";
import api from './api';
export const analysislist = (params) => {
    return api.get(`/constitutionreport/list`,params)
};
export const analysisitem = (id,params)=> {
    return api.get(`/record/question/${id}`,params)
};

export const analysisitemByMrId = (id,params)=> {
    return api.get(`/constitutionreport/infoByMrId/${id}`,params)
};

