<template>
  <div>
    <div v-if="pageloading" class="we-flex-row we-flex-center we-justify-content-center">
      <van-loading type="spinner" />
    </div>
    <!-- <van-nav-bar title left-text="返回" left-arrow @click-left="() => $router.push({name:'reportlist'})" /> -->
    <div v-else>
      <div class="tzbghead"></div>
      <div class="topposition">
        <!-- <div class="table-wrap">
          <div class="head">
            <van-row>
              <van-col span="8">体质</van-col>
              <van-col span="8">原始分</van-col>
              <van-col span="8">转化分</van-col>
            </van-row>
          </div>
          <div class="body" v-if="crdList.length">
            <van-row>
              <van-col span="8">平和质</van-col>
              <van-col span="8">{{crdList[0].original}}</van-col>
              <van-col span="8">{{crdList[0].conversion}}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">气虚质</van-col>
              <van-col span="8">{{crdList[1].original}}</van-col>
              <van-col span="8">{{crdList[1].conversion}}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">阳虚质</van-col>
              <van-col span="8">{{crdList[2].original}}</van-col>
              <van-col span="8">{{crdList[2].conversion}}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">阴虚质</van-col>
              <van-col span="8">{{crdList[3].original}}</van-col>
              <van-col span="8">{{crdList[3].conversion}}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">痰湿质</van-col>
              <van-col span="8">{{crdList[4].original}}</van-col>
              <van-col span="8">{{crdList[4].conversion}}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">湿热质</van-col>
              <van-col span="8">{{crdList[5].original}}</van-col>
              <van-col span="8">{{crdList[5].conversion}}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">血瘀质</van-col>
              <van-col span="8">{{crdList[6].original}}</van-col>
              <van-col span="8">{{crdList[6].conversion}}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">气郁质</van-col>
              <van-col span="8">{{crdList[7].original}}</van-col>
              <van-col span="8">{{crdList[7].conversion}}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">特禀质</van-col>
              <van-col span="8">{{crdList[8].original}}</van-col>
              <van-col span="8">{{crdList[8].conversion}}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">总分</van-col>
              <van-col span="8">{{totalOriginal}}</van-col>
              <van-col span="8">{{totalConversion}}</van-col>
            </van-row>
          </div>
        </div> -->

        <div class="table-wrap">
          <div class="chart-wrap">
            <v-chart :options="options" />
          </div>
          <div class="we-wrap-mgs we-text-p15 we-bg-color-light">
            <div class="lineheight">根据中华中医药学会标准，您的体质{{constitution}}{{tendency}}。</div>

            <!-- <div class="smalltitle"><span class="deriction"></span>健康建议</div> -->

          </div>
          <div class="we-wrap-mgs we-text-color-grey">
            <div class=" lineheight">若您想获取更加全面的评估报告及调理方案，请联系医生进行舌脉信息检测。</div>

            <!-- <div class="smalltitle"><span class="deriction"></span>健康建议</div> -->

          </div>
        </div>
        <div class="buttton-close" @click="weixinClosePage">完成</div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  Col,
  Row,
  Cell,
  Field,
  Grid,
  GridItem,
  Overlay,
  Loading,
  Button,
} from "vant";
import { analysisitem } from "@api/analysis";
import moment from "moment";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/visualMap";
import "echarts/lib/component/dataset";

export default {
  name: "analysisdetail",
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    "v-chart": ECharts,
  },
  data() {
    return {
      show: false,
      constitution: "",
      totalConversion: "", //转化总分
      totalOriginal: "", //原始总分
      crdList: [],
      pageloading: true,
      propose: "", //健康建议
      tendency: "",
      options: {
        legend: {
          data: ["体质得分", "我的体质"],
          selectedMode: false,
          icon: "circle",
          show: false,
        },

        xAxis: {
          type: "category",
          data: [
            "平和质",
            "气虚质",
            "阳虚质",
            "阴虚质",
            "痰湿质",
            "湿热质",
            "血瘀质",
            "气郁质",
            "特禀质",
          ],
          axisLabel: {
            color: "#333",
            //  让x轴文字方向为竖向
            interval: 0,
            formatter: function (value) {
              return value.split("").join("\n");
            },
          },

          axisTick: {
            show: false,
          },
        },
        yAxis: {
          show: true,
          type: "value",
          max: 100,
        },

        series: [
          {
            name: "体质得分",
            data: [],
            type: "bar",
            itemStyle: {
              color: "rgba(0,140, 56, 1)",
            },
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
            label: {
              show: false,
            },
            // markLine: {
            //   symbol: ["none", "none"],
            //   data: [
            //     {
            //       name: "判定",
            //       yAxis: 60,
            //       lineStyle: {
            //         width: 1,
            //         type: "solid",
            //         color: "rgba(0, 160, 233, 1)",
            //       },
            //       label: { show: true, formatter: "判定" },
            //     },
            //     {
            //       name: "倾向",
            //       yAxis: 30,
            //       lineStyle: {
            //         width: 1,
            //         color: "rgba(0, 0, 0, 0.3)",
            //       },
            //       label: { show: true, formatter: "倾向" },
            //     },
            //   ],
            //   animation: false,
            // },

            barWidth: 20,
          },
          {
            name: "我的体质",
            data: [],
            type: "bar",
            itemStyle: {
              color: "#248cca",
            },
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
            label: {
              show: false,
            },

            barWidth: 1,
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    weixinClosePage() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.weixin_ClosePage,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.weixin_ClosePage);
          document.attachEvent("onWeixinJSBridgeReady", this.weixin_ClosePage);
        }
      } else {
        this.weixin_ClosePage();
      }
    },
    weixin_ClosePage() {
      // WeixinJSBridge.call("closeWindow");
      WeixinJSBridge.invoke("closeWindow", {}, function (res) {
        //alert(res.err_msg);
      });
    },

    //获取报告详情
    ajax_getAnalysisItem(val) {
      analysisitem(val).then((res) => {
        if (res.respCode == 200) {
          this.pageloading = false;
          const question = res.result.question;
          this.constitution = question.constitution;
          this.tendency = question.tendency;
          let data = [
            { value: question.pingheNum },
            { value: question.qixuNum },
            { value: question.yangxuNum },
            { value: question.yinxuNum },
            { value: question.tanshiNum },
            { value: question.shireNum },
            { value: question.xueyuNum },
            { value: question.qiyuNum },
            { value: question.tebingNum },
          ];

          this.options.series[0].data = data.map((item) => {
            let { value, itemStyle } = item;
            if (item.value >= 60 && item.value <= 100) {
              itemStyle = { color: " #ff720d" };
            } else if (item.value < 60 && item.value >= 30) {
              itemStyle = { color: "#248cca" };
            } else {
              itemStyle = { color: "#ffeb00" };
            }
            return { value, itemStyle };
          });
          // this.crdList = res.result.crdList;
          // this.totalConversion = res.result.totalConversion;
          // this.totalOriginal = res.result.totalOriginal;
          // this.propose = res.result.propose;
          // // let bardata = []
          // this.options.series[0].data = []
          // res.result.crdList.map((item) => {
          //   item.value = item.conversion;
          //   if (item.isDecide == 1) {
          //     item.itemStyle = { color: "rgba(0, 161, 233, 1)" };
          //   }
          //   return item;
          // });
        } else {
          this.pageloading = false;
          this.$toast(res.respCode);
        }
      });
    },
  },
  mounted() {
    const val = this.$route.params.id;
    this.ajax_getAnalysisItem(val);
    document.body.scrollIntoView();
  },
};
</script>
<style lang="less">
.tzbghead {
  background-color: #008b38;
  height: 100px;
  color: #fff;
  padding: 15px;
  line-height: 30px;
}

.topposition {
  position: relative;
  top: -71px;
}
.van-col {
  // padding: 0.2rem;
  text-align: center;
  height: 36px;
  line-height: 36px;
}
.head {
  .van-row {
    // border-top: 1px solid #eee;
    // border-bottom: 1px solid #eee;
    .van-col {
      background: #b1e5c6;
      opacity: 1;
      color: #008b38;
    }
  }
}
.body {
  .van-col--8 {
    background-color: #fff;
    color: #202020;
    border-bottom: 1px solid #b1e5c6;
    // border-right: 1px solid #eee;
  }
  .van-col--8:nth-child(2) {
    border-right: 1px solid #b1e5c6;
    border-left: 1px solid #b1e5c6;
  }
}
.chart-wrap {
  width: 100%;
  height: 300px;
  background-color: #fff;
  padding-top: 20px;
  .echarts {
    width: 100%;
    height: 300px;
    position: relative;
    // left: -20px;
    margin: 0 auto;
  }
}
.smalltitle {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  .deriction {
    width: 2px;
    height: 16px;
    border-radius: 2px;
    // background-color: #008c38;
    display: inline-block;
    margin-right: 4px;
  }
}
.buttton-close {
  width: 200px;
  margin: 0 auto;
  height: 46px;
  line-height: 46px;
  text-align: center;
  color:#fff;
  background-color: #009C30;
  margin:20px auto;
  border-radius: 22px;
  font-size:18px;
}
</style>